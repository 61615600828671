.footer-container{
    position: relative;
}
.blur-footer{
  width: 20rem;
  height: 30rem;
  left: 30rem;

}
.footer-container >hr{
    position: absolute;
    width: 90%;
    border: 1px solid white;
    margin: -10px auto;
    left: 4rem;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
}
.social{
    display: flex;
    gap: 4rem;
}
.social>img{
    width: 2rem;
    height: 2rem;
}
.logo-f>img{
    width: 15rem;
}