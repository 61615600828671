.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    display: flex;
    gap: 1.5rem;
}
.logo-l{
    width: 100px;
    height: 100px;
}
.logo-r{
    width: 15rem;
    height:7rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header-menu>li{
    cursor: pointer;
}
.header-menu>li:hover,
.header-menu>li:focus{
    cursor: pointer;
    color: orange;
}

@media screen and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}