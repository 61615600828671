.join {
  display: flex;
  gap: 10rem;
  padding: 0 2rem;
}
.left-j {
  gap: 1rem;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}
.left-j > hr {
  border: 2px solid orange;
  width: 10.5rem;
  position: absolute;
  border-radius: 20%;
  margin: -10px 0;
}
.right-j {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.email-container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}
.email-container > input {
  border: none;
  outline: none;
  color: black;
  background-color: transparent;
}
::placeholder {
  color: white;
}
.btn-j {
  background-color: var(--orange);
  color: white;
  border-radius: 1rem;
}

@media screen and (max-width:768px){
  .join{
    flex-direction: column;
    gap: 1rem;
  }
  .left-j{
    font-size: x-large;
    flex-direction: column;
  }
  .right-j{
    padding: 2rem;
  }
}
